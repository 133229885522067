<template>
    <div ref="panel" class="scroll-div" style="padding-top: 0;">
        <template v-if="message">
            <ts-error style="width: 100%;"></ts-error>
        </template>
        <template v-else>
            <ts-table v-if="loaded" ref="table" :cols="cols" :data="list" :style="{ height: realHeight + 'px' }" full
                :loading="loading" hide-detail hide-page>
                <template #left>
                    <ts-table-operate hide-search show-export @leadingOut="exportInfo()">
                    </ts-table-operate>
                </template>
            </ts-table>

            <div class="total-title__line"></div>

            <div class="total-title">
                各省历年药品销售占比表
                <div class="total-title__right">筛选：
                    <ts-year-select v-model="year" @change="packageData"></ts-year-select>
                </div>
            </div>
            <div :id="nameKey" class="canvas small" v-loading="loading"></div>
        </template>

    </div>
</template>

<script>
export default {
    props: ['comName', 'baseQuery', 'visitId'],
    data() {
        return {
            nameKey: 'newProvinceYear',
            cols: this.$help.generalCols([
                {
                    fieldName: 'provinceName',
                    name: '省份',
                    listQueryView: "是",
                    listOrderView: true,
                    width: 110,
                },
                ...this.$variable.years.map(item => {
                    return {
                        fieldName: 'ratio' + item.value,
                        name: item.value + '年%',
                        listOrderView: true,
                        width: 120,
                        listClass: 'tc'
                    }
                })
            ]),
            sourceList: [],
            list: [],
            message: '',
            loading: false,
            year: this.$variable.lastYear.key,

            loaded: false,
            realHeight: 100,
        }
    },
    created() {
        this.loading = true
        // 旧 province/aimDrugsSaleYearCity/drugsProvinceYearRatio
        this.$api.get('province/saleProvince/drugsProvinceYearRatio', { year: this.year }, { 'Visit-Id': this.$help.getVisitId(this.visitId) })
            .then(res => {
                if (res.code === this.$variable.successCode) {
                    this.list = res.data.map(item => {
                        this.$variable.years.forEach(year => {
                            // console.log(`ratio${year.key}`)
                            item[`ratio${year.value}`] = this.$help.parseFloat100(item[`ratio${year.value}`])
                        })
                        return item
                    })
                    this.packageData()
                } else {
                    this.message = true
                }
            })
            .finally(() => { this.loading = false })
    },
    mounted() {
        this.realHeight = this.$refs.panel.clientHeight - 90
        this.loaded = true
    },
    methods: {
        packageData() {
            //this.$variable.years
            const list = this.list.map(item => {
                item.value = parseFloat(item[`ratio${this.$variable.yearMap[this.year]}`])
                item.name = item.provinceName
                return item
            }).sort((a, b) => {
                return b.value - a.value
            }) //.slice(0,10)
            this.$help.renderPie(this.nameKey, list)
        },
        exportInfo(txt = '确定导出吗', query = {}) {
            this.$store.commit('confirm', {
                content: txt,
                callback: (next, back) => {
                    // 旧 province/aimDrugsSaleYearCity/exportDrugsProvinceYearRatio
                    this.$api.get('province/saleProvince/exportDrugsProvinceYearRatio', this.$help.joinObj({ year: this.year }, query))
                        .then(res => {
                            if (!(res.success || res.code === 'N0001')) {
                                next()
                                this.$store.commit('error', res.message)
                            } else if (res.code === 'N0001') {
                                next()
                                this.exportInfo(res.message, { force: true })
                            } else {
                                this.$help.getExportStatus(res.data.id, data => {
                                    if (data === true) {
                                        next()
                                        this.$store.commit('success', '导出成功')
                                    } else {
                                        back()
                                        this.$store.commit('error', data)
                                    }
                                })
                            }
                        })
                        .catch(back)
                },
            })
        },
    }
}
</script>